import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Toggle from './Toggle';
import "./layout.css"
import sun from '../assets/sun.png';
import moon from '../assets/moon.png';

class Layout extends React.Component {
  state = {
    theme: null,
  };

  componentDidMount() {
    this.setState({ theme: window.__theme });
    window.__onThemeChange = () => {
      this.setState({ theme: window.__theme });
    };
  }

  render() {
    const rootPath = `${__PATH_PREFIX__}/`;
    return (
      <div style={{
        margin: `0 auto`,
        maxWidth: 800,
        padding: `1.45rem 1.0875rem`,
      }}>
        <header style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2.625rem',
        }}>
          <h1
            style={{
              marginBottom: 0,
              marginTop: 0,
            }}>
            <Link
              to="/"
              style={{
                color: this.props.location.pathname === rootPath ? `var(--textNormal)` : `var(--textLink)`,
                textDecoration: `none`,
              }}
            >
              {this.props.title}
            </Link>
          </h1>
          {this.renderToggle()}
        </header>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 800,
            color: `var(--textNormal)`
          }}
        >
          <main>{this.props.children}</main>
          <footer style={{
            marginTop: `2rem`,
          }}>
            © {new Date().getFullYear()} zjn
          </footer>
        </div>
      </div>
    )
  }

  renderToggle() {
    return (
      <Toggle
        icons={{
          checked: (
            <img
              src={moon}
              width="16"
              height="16"
              role="presentation"
              style={{ pointerEvents: 'none' }}
            />
          ),
          unchecked: (
            <img
              src={sun}
              width="16"
              height="16"
              role="presentation"
              style={{ pointerEvents: 'none' }}
            />
          ),
        }}
        checked={this.state.theme === 'dark'}
        onChange={e =>
          window.__setPreferredTheme(
            e.target.checked ? 'dark' : 'light'
          )
        }
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
